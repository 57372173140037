<template>
	<v-btn
		:color="joinButtonColor"
		:loading="(actionStarted && !actionFinished) || loadingParent"
		:outlined="course.joined || (actionStarted && !actionFinished)"
		:disabled="course.joined || actionStarted"
		@click.stop="callJoinCourse()"
		rounded
	>
		<span>{{ joinButtonText }}</span>
	</v-btn>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'CourseJoinButton',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
		course: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			joinButtonText: '',
			actionStarted: false,
			actionFinished: false,
			actionSuccess: false,
		}
	},
	computed: {
		joinButtonColor() {
			if (this.actionFinished && this.actionSuccess) {
				return 'success'
			} else if (this.actionFinished && !this.actionSuccess) {
				return 'warning'
			} else {
				return 'primary'
			}
		},
	},
	created() {
		if (this.course.joined) {
			this.joinButtonText = this.$t('settings.added')
		} else {
			this.joinButtonText = this.$t('settings.add')
		}
	},
	methods: {
		callJoinCourse() {
			if (!this.actionStarted && !this.course.joined) {
				this.actionStarted = true
				this.joinButtonText = this.$t('search.loading')
				this.joinCourse(this.course.id)
					.then((data) => {
						this.actionSuccess = data.success
						this.joinButtonText = data.success ? this.$t('settings.added') : 'Error'
					})
					.catch(() => {
						this.actionSuccess = false
						this.joinButtonText = this.$t('alerts.error')
					})
					.then(() => {
						this.actionFinished = true
					})
			}
		},
		...mapActions('courses', ['joinCourse']),
	},
}
</script>
